<template>
  <div id="webchat" />
</template>

<script>
export default {
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      window.botpressWebChat.init({
        "composerPlaceholder": "Live Chat with Quantumexchanger",
        "botConversationDescription": "Live Chat with Quantumexchanger",
        "botId": "8c921477-d521-4b68-9b59-2ff18ee5d045",
        "hostUrl": "https://cdn.botpress.cloud/webchat/v1",
        "messagingUrl": "https://messaging.botpress.cloud",
        "clientId": "8c921477-d521-4b68-9b59-2ff18ee5d045",
        "webhookId": "8dc5318b-ed6a-47cf-b0b0-9faa41ff5d20",
        "lazySocket": true,
        "themeName": "prism",
        "botName": "Live chat",
        "avatarUrl": "https://cdn.dribbble.com/users/2034846/screenshots/13942269/dribble_illustration_1600x1200_magmabot_4x.png",
        "frontendVersion": "v1",
        "theme": "prism",
        "themeColor": "#2563eb"
      });
    }
  }
}
</script>

<style scoped>

</style>