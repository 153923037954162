
import Vue from 'vue'

import App from './App.vue'
import router from './router.js'
import './moment'
import './vee-validate'
import './toast'
import store from './store';
import i18n from './i18n';
import axios from './axios'

// import './event_bus'
Vue.prototype.$http = axios
Vue.prototype.$http.defaults.withCredentials = true;

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import './../node_modules/slick-carousel/slick/slick.css';
import '../public/css/style.scss';
import 'promise-polyfill/src/polyfill';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

import Dropdown from './components/Dropdown.vue'
Vue.component('Dropdown', Dropdown);

new Vue({
	router: router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')
