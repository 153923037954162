<template>
  <div class="modal recover-modal">
    <div class="overlay"  @click="$parent.closeRecover"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeRecover" src="./../images/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">{{ $t(`Forgot password?`) }}</div>
                <label>
                  <input :placeholder="$t(`Email`) + ` *`" type="email" v-model="email" autocomplete="username"/>
                </label>
                <button class="button" @click="submitRecover">
                  <span>{{ $t(`Recover`) }}</span>
                </button>
                <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
                <div v-if="$parent.successMessage" class="desc green">{{ $parent.successMessage }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RecoverModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: ''
    }
  },
  mounted() {
    
  },
  computed: {
    
  },
  methods: {
    submitRecover() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    }
  }
}
</script>