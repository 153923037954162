<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../assets/logo.svg" class="img" />
        </router-link>

      </div>


      <!--      <div class="middle">-->
      <!--        <ul class="nav">-->
      <!--          <template v-for="item in $parent.headerNav">-->
      <!--            <li class="nav__item" :key="item.title">-->
      <!--              <router-link class="desc" :to="item.path">-->
      <!--                {{ item.title }}-->
      <!--              </router-link>-->
      <!--            </li>-->
      <!--          </template>-->
      <!--        </ul>-->
      <!--      </div>-->
      <div class="right">
        <Dropdown v-if="allLanguages.length > 0" :options="allLanguages" value-key="id" label-key="name"
          placeholder="Language" :default-value="currentLanguage" container-class="language-dropdown"
          @select="selectLanguage" />
        <router-link class="header-link exchange-link hover-link" to="/buy-crypto">
          <span class="header-link__text">
            {{ $t('Exchange') }}
          </span>
        </router-link>
        <router-link v-if="$parent.isAuth" class="header-link profile-link hover-link" to="/profile">
          <span class="header-link__text">
            {{ $t('Profile') }}
          </span>
        </router-link>
        <div v-else class="header-link profile-link hover-link" @click="$parent.openSignInModal">
          <span class="header-link__text">{{ $t(`Login`) }}</span>
        </div>
        <!--        <div v-if="!$parent.isAuth" class="user hover-link" @click="$parent.openSignInModal">-->
        <!--          <img src="./../images/user.svg" class="img"/>-->
        <!--        </div>-->
        <!--        <router-link v-if="$parent.isAuth" class="user hover-link" to="profile">-->
        <!--          <img src="./../images/user.svg" class="img"/>-->
        <!--        </router-link>-->
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Header',
  props: [],
  components: {
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters("app", ['currentLanguage', 'allLanguages']),
  },
  methods: {
    selectLanguage(lang) {
      this.changeLocale(lang.id);
    },

    ...mapActions('app', ['changeLocale']),

  }
}
</script>
<style lang="scss">
.header .wrapper .select-container.language-dropdown {
  position: relative;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 16px;
  padding: 8px 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  line-height: 24px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  max-width: 100px;
  width: 100%;
  color: #141427;
  cursor: pointer;

  &:focus {
    border: 1px solid #ddd;
  }

  .dropdown-selected {}

  .dropdown-menu {
    top: 110%;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  }

  .dropdown-selected:hover,
  .dropdown-item:hover {
    opacity: .7;
  }
}
</style>