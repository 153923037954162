<template>
  <div class="modal register-modal">
    <div class="overlay" @click="$parent.closeRegister"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeRegister" src="./../images/close.svg" />
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">{{ $t(`Create account`) }}</div>
                <label>
                  <input ref="dob" @focus="showDatePicker" @blur="hideDatePicker" type="text"
                    :placeholder="$t(`Date of Birth`) + ` *`" v-model="dob" required />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`First name`) }}</div>-->
                  <input type="text" :placeholder="$t(`First name`) + ` *`" v-model="name" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Last Name`) }}</div>-->
                  <input type="text" :placeholder="$t(`Last Name`) + ` *`" v-model="surname" />
                </label>
                <label v-if="countryOptionsIso && countryOptionsIso.length">
                  <!-- <div class="desc">{{ $t(`Phone`) }}</div>-->
                  <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                    :default-country-code="countryOptionsIso[0]" @update="phoneDataUpdate($event)" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Email`) }}</div>-->
                  <input type="email" :placeholder="$t(`Email`) + ` *`" v-model="email" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Password`) }}</div>-->
                  <input type="password" :placeholder="$t(`Password`) + ` *`" v-model="pass" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Confirm Password`) }}</div>-->
                  <input type="password" :placeholder="$t(`Confirm Password`) + ` *`" v-model="passConfirm" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Address`) }}</div> -->
                  <input type="text" :placeholder="$t(`Address`)" v-model="address" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Postal Code`) }}</div> -->
                  <input type="text" :placeholder="$t(`Postal Code`)" v-model="postCode" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`City`) }}</div> -->
                  <input type="text" :placeholder="$t(`City`)" v-model="city" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Country`) }}</div> -->
                  <Dropdown :options="countryOptions" value-key="id" label-key="title" placeholder="Country"
                    container-class="country-dropdown" :container-style="{ width: '100%', marginTop: '12px' }" :clearable="true"
                    @select="onCountrySelect" @clear="onCountryClear"
                    :not-selected-option-style="{ color: '#a9a9a9' }" />
                </label>
                <div class="link-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms" />
                        <div class="checkbox"></div>
                        <span class="desc">{{ $t(`I agree with`) }}</span>
                        <a @click="$parent.goToPrivacy()" class="desc link"> {{ $parent.textPageList.filter(item =>
      item.is_privacy)[0].title }} </a>
                        <span class="desc">{{ $t(`and`) }}</span>
                        <a @click="$parent.goToTerms()" class="desc link"> {{ $parent.textPageList.filter(item =>
      item.is_terms)[0].title }}</a>
                      </div>
                    </label>
                  </div>
                </div>
                <button :class="['button', { 'disabled': !requiredRegisterFieldsAreFilled }]" @click="submitRegister">
                  <span>{{ $t(`Create Account`) }}</span>
                </button>
                <div class="desc link" @click="$parent.openSignInModal">{{ $t(`Sign In!`) }}</div>
                <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterModal',
  props: [],
  components: {
  },
  data: function () {
    return {
      dob: null,
      email: '',
      name: '',
      surname: '',
      phone: '',
      pass: '',
      passConfirm: '',
      terms: false,
      phoneFull: "",
      
      address: '',
      postCode: '',
      city: '',
      country: "",
      countryOptions: [],
      countryOptionsIso: []

    }
  },
  mounted() {
    this.getCountries();
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.dob &&
        this.name && this.surname && this.phone &&
        this.email && this.pass && this.passConfirm
        && this.terms
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    onCountrySelect(country) {
      this.country = country;
    },
    onCountryClear() {
      this.country = '';
    },
    showDatePicker() {
      this.$refs.dob.type = 'date';
      this.$refs.dob.focus();
    },
    hideDatePicker() {
      if (!this.dob) {
        this.$refs.dob.type = 'text';
        this.dob = '';
      }
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    submitRegister() {
      let regData = {
        "birth_date": this.dob,
        "name": this.name,
        "surname": this.surname,
        "phone": this.phoneFull,
        "email": this.email,
        "password": this.pass,
        "passConfirm": this.passConfirm,

        "address": this.address,
        "post_code": this.postCode,
        "city": this.city,
        "country_id": this.country.id
      }
      this.$emit('registration', regData)
    }
  }
}
</script>