<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="content">
        <div class="logo-icons">
          <div class="logo">
            <img
              src="./../assets/logo2.svg"
              class="img"
            />
          </div>
          <div class="icons">
            <a
              :href="link.link"
              v-for="link in $parent.socialLinks"
              class="link"
              v-bind:key="link.link"
              target="_blank"
            >
              <img :src="link.img" />
            </a>
          </div>
        </div>

        <div class="links">
          <div
            v-for="item in $parent.links.slice(0, 3)"
            :key="item.title"
            class="links__item"
          >
            <router-link
              v-if="item.path"
              :to="item.path"
            >
              {{ item.title }}
            </router-link>
            <a
              v-else
              @click="$parent.goToPage(item)"
            >
              {{ item.title }}
            </a>
          </div>
        </div>

        <div class="links">
          <div
            v-for="item in $parent.links.slice(3)"
            :key="item.title"
            class="links__item"
          >
            <router-link
              v-if="item.path"
              :to="item.path"
            >
              {{ item.title }}
            </router-link>
            <a
              v-else
              @click="$parent.goToPage(item)"
            >
              {{ item.title }}
            </a>
          </div>
        </div>

        <div class="legal-info">
          <div class="legal-info__info">{{ $parent.legalInfo }}</div>
          <div class="legal-info__vsmc">

            <!-- <img :src="$parent.appDomain + method.image" v-for="method in paymentMethodsList" :key="method.code"
              class="payment-method img" /> -->
            <ul
              class="payment-methods-list"
              v-if="allFooterImages && allFooterImages.length"
            >
              <li
                v-for="image in allFooterImages"
                :key="image"
                class="image-wrapper"
              >
                <img
                  :src="image"
                  class="support-icon img"
                />
              </li>
            </ul>
            <!-- <img src="../images/mc.svg" alt="">
            <img src="../images/visa-logo.svg" alt=""> -->
          </div>
        </div>
      </div>
      <div class="copyright">
        © {{ new Date().getFullYear() }} All Rights Reserved
      </div>
    </div>

    <!--		<div class="wrapper">-->
    <!--			<ul class="nav">-->
    <!--				<template  v-for="item in $parent.footerNav">-->
    <!--					<li class="nav__item" :key="item.title">-->
    <!--						<router-link class="desc" :to="item.path">-->
    <!--							{{item.title}}-->
    <!--						</router-link>-->
    <!--					</li>-->
    <!--				</template>-->
    <!--				<template  v-for="item in $parent.textPageList">-->
    <!--					<li class="nav__item" :key="item.id">-->
    <!--						<a class="desc" @click="$parent.goToPage(item)">-->
    <!--						{{item.title}}-->
    <!--						</a>-->
    <!--					</li>-->
    <!--				</template>-->
    <!--			</ul>-->
    <!--			<div v-if="hasCountryObject" class="nav__item allowed-countries">-->
    <!--				<a @click="$parent.goToAllowedCountries" class="desc">-->
    <!--					Allowed countries-->
    <!--				</a>-->
    <!--			</div>-->
    <!--		</div>-->
    <!--    <div class="wrapper wrapper_social">-->
    <!--      <div class="social-links">-->
    <!--        <div v-for="link in $parent.socialLinks" :key="link.img" class="social_links__link">-->
    <!--          <a :href="link.link">-->
    <!--            <img :src="link.img" :alt="link.link"/>-->
    <!--          </a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--		<div class="wrapper">-->
    <!--			<div v-if="$parent.legalInfo" class="desc">{{$parent.legalInfo}}</div>-->
    <!--			<div class="list">-->
    <!--				<div class="item" v-if="$parent.phone">-->
    <!--					<div class="desc">{{ $t(`Phone`) }}:</div>-->
    <!--					<a :href="'tel:' + $parent.phone" class="desc">{{$parent.phone}}</a>-->
    <!--				</div>-->
    <!--				<div class="item" v-if="$parent.email">-->
    <!--					<div class="desc">{{ $t(`E-mail:`) }}</div>-->
    <!--					<a :href="'mailto:' + $parent.email" class="desc">{{$parent.email}}</a>-->
    <!--				</div>-->
    <!--			</div>-->
    <!--		</div>-->
    <!--		<div class="wrapper">-->
    <!--			<div class="desc">© 2023 All Rights Reserved</div>-->
    <!--			<ul class="list">-->
    <!--				<li class="item">-->
    <!--				<img src="./../images/mc.svg" class="img"/>-->
    <!--				</li>-->
    <!--				<li class="item">-->
    <!--				<img src="./../images/visa.svg" class="img"/>-->
    <!--				</li>-->
    <!--				<li class="item">-->
    <!--				<img src="./../images/3ds.png" class="img"/>-->
    <!--				</li>-->
    <!--			</ul>-->
    <!--		</div>-->
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  props: {
    footerImages: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data: function () {
    return {
      // legalInfo: '',
      // email: '',
      // phone: ''
    }
  },
  methods: {
  },
  computed: {
    allFooterImages() {
      return this.footerImages;
    },
    hasCountryObject() {
      return this.$parent.textPageList.some(obj => obj.hasOwnProperty("is_countries") && obj.is_countries === 1);
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.footer .payment-methods-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .image-wrapper {
    /* background-color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    max-width: 70px;
  }

  .support-icon.img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}
</style>