<template>
  <div class="crypto-section">
    <div class="input-container">
      <input
        type="number"
        :value="takeAmount"
        @input="$emit('update:take-amount', $event.target.value); changeGiveAmount($event.target.value);"
      />
      <div class="desc">{{ takeCurrency.crypto_currency }}</div>
    </div>
    <div
      v-if="!$parent.fiatToCrypto"
      class="radio-list payment-method-list"
    >
      <div class="desc">{{ $t(`Payment method:`) }}</div>
      <div class="list">
        <div class="item">
          <label>
            <input
              type="radio"
              v-model="paymentMethod"
              @change="$emit('update:payment-method', $event)"
              name="paymentMethod"
              value="crypto"
            />
            <div class="text">
              <div class="desc">{{ $t(`Crypto`) }}</div>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="radio-list cryptocurrency-list">
      <div class="desc title">{{ $t(`Assets:`) }}</div>
      <div
        class="list"
        v-if="filteredRates && filteredRates.length"
      >
        <div
          class="item"
          v-for="item in filteredRates"
          :key="item.id"
        >
          <label>
            <input
              type="radio"
              :checked="takeCurrency.crypto_currency === item.crypto_currency"
              @change="$emit('update:take-currency', item)"
              :value="item.crypto_currency"
            />
            <div class="text">
              <div class="desc">{{ item.crypto_currency }} {{ item.price }} : 1</div>
            </div>
          </label>
        </div>
      </div>

      <div
        class="list"
        v-else
      >
        <div class="item">
          <label>
            <input
              type="radio"
              checked
            />
            <div class="desc">{{ $t(`No assets available`) }}</div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CryptoSection',
  props: {
    giveCurrency: {
      type: Object
    },
    rates: {
      type: Array
    },
    filteredRates: {
      type: Array
    },
    takeCurrency: {
      type: Object
    },
    takeAmount: {
      type: Number
    },
    changeGiveAmount: {
      type: Function
    },
    toggleFiatToCrypto: {
      type: Function
    },
    paymentMethod: {
      type: String
    }
  },
  data: function () {
    return {}
  },
  methods: {

  },
  computed: {},
  mounted() {
  }
}
</script>
