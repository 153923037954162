<template>
<div class="header-wrapper section hero-section">
  <slot></slot>
<!--  <div class="ellipse ellipse-2"></div>-->
</div>
</template>

<script>

export default {
  name: 'HeaderWrapper',
  props: [],
  data: function() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>