<template>
  <div class="modal register-modal">
    <div class="overlay" @click="$parent.closePersonalInfoModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closePersonalInfoModal" src="./../images/close.svg" />
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">{{ $t(`Checkout`) }}</div>
                <label>
                  <input type="text" :placeholder="$t(`First name`) + ` *`" v-model="name" />
                </label>
                <label>
                  <input type="text" :placeholder="$t(`Last Name`) + ` *`" v-model="surname" />
                </label>
                <label v-if="!$parent.fiatToCrypto && !profileBirthdate">
                  <input ref="dob" @focus="showDatePicker" @blur="hideDatePicker" type="text"
                    :placeholder="$t(`Birthdate`) + ` *`" v-model="birthdate" required />
                </label>
                <label>
                  <input type="email" :placeholder="$t(`Email`) + ` *`" v-model="email" />
                </label>
                <label>
                  <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                    :default-country-code="defaultCountryIso" @update="phoneDataUpdate($event)" />
                </label>
                <label>
                  <input type="text" :placeholder="$t(`Address`) + ` *`" v-model="address" />
                </label>
                <label>
                  <input type="text" :placeholder="$t(`City`) + ` *`" v-model="city" />
                </label>
                <label>
                  <input type="text" :placeholder="$t(`Post code`) + ` *`" v-model="postCode" />
                </label>
                <label>
                  <!--                  <div class="desc">{{ $t(`Country`) }}</div>-->
                  <Dropdown :options="countryOptions" value-key="id" label-key="title" placeholder="Country"
                    :default-value="defaultCountry" container-class="country-dropdown"
                    :container-style="{ width: '100%' }" :clearable="true" @select="onCountrySelect"
                    @clear="onCountryClear" :not-selected-option-style="{ color: '#a9a9a9' }" />
                </label>
                <div class="link-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms" />
                        <div class="checkbox"></div>
                        <span class="desc">{{ $t(`I agree with`) }}</span>
                        <a @click="$parent.goToPrivacy()" class="desc link"> {{ $parent.textPageList.filter(item =>
      item.is_privacy)[0].title }} </a>
                        <span class="desc">{{ $t(`and`) }}</span>
                        <a @click="$parent.goToTerms()" class="desc link"> {{ $parent.textPageList.filter(item =>
      item.is_terms)[0].title }}</a>
                      </div>
                    </label>
                  </div>
                </div>
                <button :class="['button', { 'disabled': !requiredRegisterFieldsAreFilled }]" @click="submit">
                  <span>{{ $t(`Submit`) }}</span>
                </button>
                <div v-if="$parent.error" class="desc red">{{ $parent.error }}</div>
                <div class="legal-info">
                  <div class="info">{{ $parent.legalInfo }}</div>
                  <div class="billing-descriptor">
                    {{ $parent.billingDescriptor }}
                  </div>
                  <!-- <div class="vsmc">
                    <img src="@/images/mc.svg" class="img" />
                    <img src="@/images/visa.svg" class="img" />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalInfoModal',
  props: [],
  components: {
  },
  data: function () {
    return {
      countryOptions: [],
      countryOptionsId: [],
      email: '',
      name: '',
      surname: '',
      profileBirthdate: null,
      birthdate: null,
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      countryOptionsIso: [],
      phoneFull: '',
      phoneCountryCode: ''

    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.profileBirthdate = res.data.birth_date;
        this.birthdate = res.data.birth_date;
        this.phone = res.data.phone;

        this.address = res.data.address;
        this.postCode = res.data.post_code;
        this.city = res.data.city;
        this.country = res.data.country;
        this.phoneCountryCode = res.data.phone_country_code;
      })
      .catch(() => {

      })
    this.getCountries();
  },
  computed: {
    defaultCountry() {
      if (this.country && this.countryOptions.length > 0) {
        return this.countryOptions.find(option => option.id === this.country?.id) || this.countryOptions[0];
      } else {
        return null;
      }
    },
    defaultCountryIso() {
      return this.phoneCountryCode || this.countryOptionsIso[0];
    },
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;

      if (
        this.name && this.surname && this.phone &&
        this.email && this.terms && this.city && this.country &&
        this.postCode && this.address && (this.$parent.fiatToCrypto ? true : this.birthdate)
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    onCountrySelect(country) {
      this.country = country;
    },
    onCountryClear() {
      this.country = '';
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    submit() {
      this.$emit('setPersonalInfo', this.email, this.name, this.surname, this.phoneFull, this.postCode, this.country.id, this.city, this.address, this.birthdate)
      this.$emit('orderSubmit');
    },
    showDatePicker() {
      this.$refs.dob.type = 'date';
      this.$refs.dob.focus();
    },
    hideDatePicker() {
      if (!this.birthdate) {
        this.$refs.dob.type = 'text';
        this.birthdate = null;
      }
    }
  }
}
</script>